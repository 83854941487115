<template>
    <button type="button" class="transition-all text-sm py-2 px-4 rounded-xl" :class="themeClasses[theme]">
        <slot></slot>
    </button>
</template>

<script setup>
defineProps({
    theme: { type: String, default: "ghost" },
});

const themeClasses = {
    ghost: "border-2 hover:bg-valence-grey-100 active:bg-valence-grey-200",
    // `startChat` isn't exactly a theme, but this is temp until we have a design system for button types
    startChat: "!rounded-full !text-base !px-6 !py-3 bg-white transition-colors font-semibold text-[#555BA2] flex gap-2 items-center whitespace-nowrap relative gradientBorder",
    primary: "!rounded-[20px] !text-base !px-5 !py-[10px] bg-[#555BA2] hover:bg-[#555BA2]/80 disabled:bg-[#555BA2]/75 text-white font-semibold",
    secondary: "!text-base font-semibold text-[#555BA2]",
};
</script>

<style scoped>
/* CSS Houdini property, baseline available 2024, fallback for unsupported browsers is to have no spinning affect */
@property --rotate {
    syntax: "<angle>";
    initial-value: 155deg;
    inherits: false;
}

.gradientBorder::before {
    content: "";
    @apply rounded-full absolute inset-0 border-2 border-transparent;
    background: linear-gradient(var(--rotate), #ff238f 0%, #555ba2 100%) border-box;
    mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
    mask-composite: exclude;
}

.gradientBorder:hover::before {
    animation: spin 3s linear infinite;
}

@keyframes spin {
    0% {
        --rotate: 155deg;
    }
    100% {
        --rotate: 515deg;
    }
}
</style>
